import 'assets/styles/app.scss';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	$('.coupon-link').click(function () {
		$(this).hide();
		$( '.coupon').show();
		$( '.coupon').slideDown( "slow" );
	});

	$('.woocommerce-NoticeGroup-checkout').insertAfter('.woocommerce-checkout-review-summery').find('h2');


	jQuery(document).ready(function ($) {
		if ($(window).width() < 992) {
			//$('.header__menu-list > li > a').removeAttr("href");
			//$('.header__menu-list > li.menu-item-has-children > div > ul > li > a').removeAttr("href");
		}

		console.log('loaded');
	});
 

} );
